import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { slide as Menu } from 'react-burger-menu';
import { useMediaQuery } from 'react-responsive';
import logo from '../../img/logo-Saxnart.png';
import './HeaderMobile.css'; // Assuming you have common styles here

const HeaderMobile = () => {
    const location = useLocation();
    const { i18n, t } = useTranslation();
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState('');

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const handleItemClick = (itemName) => setSelectedItem(itemName);

    const changeLanguage = (lng) => i18n.changeLanguage(lng);

    if (isMobile) {
        return (
            <div className="navbar flex flex-col text-white h-16 w-full md:w-[80%] items-start md:items-center relative z-50 bg-black bg-opacity-50">
                <div className="md:hidden">
                    <button onClick={toggleMenu} className="text-white text-3xl mt-4 ml-4">
                        &#9776;
                    </button>
                </div>

                <Menu isOpen={isMenuOpen} customBurgerIcon={false} customCrossIcon={false} className="bg-black/[.9]">
                    <Link to="/" className="menu-item ml-2" onClick={toggleMenu}>
                        {t('Trang Chủ')}
                    </Link>
                    <Link to="/event" className="menu-item ml-2" onClick={toggleMenu}>
                        {t('Sự Kiện')}
                    </Link>
                    <div className="menu-item menu-item-has-children" onClick={() => handleItemClick('sax-nart')}>
                        <span
                            className={`ml-2 cursor-pointer relative ${selectedItem === 'sax-nart' ? 'selected' : ''}`}
                        >
                            {t("Chuyện Của Sax N'Art")}
                            <ul className={`submenu ${selectedItem === 'sax-nart' ? 'visible' : 'hidden'}`}>
                                <li>
                                    <Link to="/sax-n'art" onClick={toggleMenu}>
                                        {t("Chuyện Sax N'Art")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/artist" onClick={toggleMenu}>
                                        {t('Chuyện Nghệ Sĩ')}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/blog" onClick={toggleMenu}>
                                        {t('PodCard & Blog')}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/gallery" onClick={toggleMenu}>
                                        {t("Sax N'Art Gallery")}
                                    </Link>
                                </li>
                            </ul>
                        </span>
                    </div>
                    {/* <div className="menu-item menu-item-has-children" onClick={() => handleItemClick('membership')}>
                        <span
                            className={`ml-2 cursor-pointer relative ${
                                selectedItem === 'membership' ? 'selected' : ''
                            }`}
                        >
                            {t('Membership')}
                            <ul className={`submenu ${selectedItem === 'membership' ? 'visible' : 'hidden'}`}>
                                <li>
                                    <Link to="/guide" onClick={toggleMenu}>
                                        {t('Hướng dẫn')}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/category" onClick={toggleMenu}>
                                        {t('Phân loại & quyền lợi')}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/hotline" onClick={toggleMenu}>
                                        {t('Hotline đặt chỗ')}
                                    </Link>
                                </li>
                            </ul>
                        </span>
                    </div> */}
                    <div className="menu-item menu-item-has-children" onClick={() => handleItemClick('contact')}>
                        <span
                            className={`ml-2 cursor-pointer relative ${selectedItem === 'contact' ? 'selected' : ''}`}
                        >
                            {t('Liên Hệ')}
                            <ul className={`submenu ${selectedItem === 'contact' ? 'visible' : 'hidden'}`}>
                                <li>
                                    <Link to="/contact" onClick={toggleMenu}>
                                        {t('Liên hệ')}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/bookingevent" onClick={toggleMenu}>
                                        {t('Đặt Sự Kiện')}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/feedback" onClick={toggleMenu}>
                                        {t('Góp Ý')}
                                    </Link>
                                </li>
                            </ul>
                        </span>
                    </div>
                    <Link
                        to="/booking"
                        className="w-[50%] md:w-[15%] h-[5%] md:h-[100%] flex items-center justify-center border border-white rounded-[30px] mt-10 md:mt-0 mx-auto"
                        style={{ textAlign: 'center' }}
                    >
                        {t('RESERVE NOW')}
                    </Link>
                    <div className="language-switcher flex justify-center items-center mt-4">
                        <button onClick={() => changeLanguage('en')} className="language-button">
                            {t('English')}
                        </button>
                        <button onClick={() => changeLanguage('vi')} className="language-button ml-2">
                            {t('Vietnamese')}
                        </button>
                    </div>
                </Menu>
            </div>
        );
    } else {
        return (
            <div className="bg-black/[.6] fixed w-full h-24 flex justify-center flex-row z-50">
                <div className="navbar flex justify-around text-white h-24 w-[80%] items-center relative z-50">
                    <Link
                        to="/"
                        className={`w-[15%] h-[100%] flex items-center justify-center hover:text-orange-600 hover:font-bold ${
                            location.pathname === '/' ? 'text-orange-600 font-bold' : ''
                        }`}
                    >
                        {t('Trang Chủ')}
                    </Link>
                    <Link
                        to="/event"
                        className={`w-[15%] h-[100%] flex items-center justify-center hover:text-orange-600 hover:font-bold ${
                            location.pathname === '/event' ? 'text-orange-600 font-bold' : ''
                        }`}
                    >
                        {t('Sự Kiện')}
                    </Link>
                    <div
                        className={`w-[15%] h-[100%] flex items-center justify-center relative group hover:text-orange-600 hover:font-bold ${
                            selectedItem === 'saxnart' ? 'text-orange-600 font-bold' : ''
                        }`}
                        onClick={() => handleItemClick('saxnart')}
                    >
                        {t("Chuyện Của Sax N'Art")}
                        <ul className="absolute text-white text-center bg-[#262626] top-[5rem] p-10 w-[16vw] invisible group-hover:visible font-sans max-h-[20rem] overflow-y-auto">
                            <li className="p-[1rem] hover:bg-orange-600 hover:scale-110 h-14 flex items-center justify-center">
                                <Link to="/sax-n'art"> {t("Chuyện Sax N'Art")} </Link>
                            </li>
                            <li className="p-[1rem] hover:bg-orange-600 hover:scale-110 h-14 flex items-center justify-center">
                                <Link to="/artist"> {t('Chuyện Nghệ Sĩ')} </Link>
                            </li>
                            <li className="p-[1rem] hover:bg-orange-600 hover:scale-110 h-14 flex items-center justify-center">
                                <Link to="/blog"> {t('PodCard & Blog')} </Link>
                            </li>
                            <li className="p-[1rem] hover:bg-orange-600 hover:scale-110 h-14 flex items-center justify-center">
                                <Link to="/gallery"> {t("Sax N'Art Gallery")} </Link>
                            </li>
                        </ul>
                    </div>
                    <Link to="/" className="w-[15%] h-[100%] flex items-center justify-center">
                        <img src={logo} alt="Saxnart Logo" className="h-12" />
                    </Link>
                    {/* <div
                        className={`w-[15%] h-[100%] flex items-center justify-center relative group hover:text-orange-600 hover:font-bold ${
                            selectedItem === 'membership' ? 'text-orange-600 font-bold' : ''
                        }`}
                        onClick={() => handleItemClick('membership')}
                    >
                        {t('Membership')}
                        <ul className="absolute text-white text-center bg-[#262626] top-[5rem] p-10 w-[16vw] invisible group-hover:visible font-sans max-h-[20rem] overflow-y-auto">
                            <li className="p-[1rem] hover:bg-orange-600 hover:scale-110 h-14 flex items-center justify-center">
                                <Link to="/guide"> {t('Hướng dẫn')} </Link>
                            </li>
                            <li className="p-[1rem] hover:bg-orange-600 hover:scale-110 h-14 flex items-center justify-center">
                                <Link to="/category"> {t('Phân loại & quyền lợi')} </Link>
                            </li>
                            <li className="p-[1rem] hover:bg-orange-600 hover:scale-110 h-14 flex items-center justify-center">
                                <Link to="/hotline"> {t('Hotline đặt chỗ')} </Link>
                            </li>
                        </ul>
                    </div> */}
                    <div
                        className={`w-[15%] h-[100%] flex items-center justify-center relative group hover:text-orange-600 hover:font-bold ${
                            selectedItem === 'contact' ? 'text-orange-600 font-bold' : ''
                        }`}
                        onClick={() => handleItemClick('contact')}
                    >
                        {t('Liên Hệ')}
                        <ul className="absolute text-white text-center bg-[#262626] top-[5rem] p-10 w-[16vw] invisible group-hover:visible font-sans max-h-[20rem] overflow-y-auto">
                            <li className="p-[1rem] hover:bg-orange-600 hover:scale-110 h-14 flex items-center justify-center">
                                <Link to="/contact"> {t('Liên hệ')} </Link>
                            </li>
                            <li className="p-[1rem] hover:bg-orange-600 hover:scale-110 h-14 flex items-center justify-center">
                                <Link to="/bookingevent"> {t('Đặt Sự Kiện')} </Link>
                            </li>
                            <li className="p-[1rem] hover:bg-orange-600 hover:scale-110 h-14 flex items-center justify-center">
                                <Link to="/feedback"> {t('Góp Ý')} </Link>
                            </li>
                        </ul>
                    </div>
                    <Link
                        to="/booking"
                        className="w-[15%] h-[100%] flex items-center justify-center border border-white rounded-[30px]"
                    >
                        {t('RESERVE NOW')}
                    </Link>
                    <div className="language-switcher flex justify-center items-center">
                        <button onClick={() => changeLanguage('en')} className="language-button">
                            {t('English')}
                        </button>
                        <button onClick={() => changeLanguage('vi')} className="language-button ml-2">
                            {t('Vietnamese')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
};

export default HeaderMobile;
